<template>
  <div style="background: #f9f9f9; padding-top: 30px; height: 100%">
    <h2 class="title1">商户信息</h2>
    <el-card class="nav">
      <!-- 步骤条 -->
      <Steps :active-index="activeIndex" />
    </el-card>
    <el-card class="main_card">
      <p class="title">商户基本信息</p>
      <el-divider class="divider"></el-divider>
      <!-- 商户信息 -->
      <el-form
        class="merchantInfoFrom"
        ref="merchantInfoFrom"
        :model="data1"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="商户名称:" prop="merName">
          <el-input
            v-model.trim="data1.merName"
            placeholder="请输入商户名称"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联系人:" prop="linkmanName">
          <el-input
            v-model.trim="data1.linkmanName"
            placeholder="请输入联系人"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="linkmanPhone">
          <el-input
            v-model.trim="data1.linkmanPhone"
            placeholder="请输入手机号"
            maxlength="11"
            disabled
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱:" prop="linkmanMail">
          <el-input
            v-model.trim="data1.linkmanMail"
            placeholder="请输入联系邮箱"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="btn-group">
      <el-button class="btn" size="small" @click="handleBack">返回</el-button>
      <el-button class="btn" size="small" @click="handleSave"
        >临时保存</el-button
      >
      <el-button
        class="btn"
        size="small"
        type="primary"
        :loading="loading"
        @click="handleNext"
        >下一步</el-button
      >
    </div>
  </div>
</template>

<script>
import Steps from '@/components/Steps'
import {
  createMerchant
} from '@/api'
export default {
  components: {
    Steps
  },
  props: [],
  data () {
    return {
      // 步骤条默认选中
      activeIndex: 1,
      merId: '',
      loading: false,
      // 商户基本信息表单
      data1: {
        merName: "",
        linkmanName: "",
        linkmanPhone: "",
        coLinkmanPhone: "",
        coLinkmanMail: "",
        linkmanMail: "",
      },
      rules: {
        merName: [
          { required: true, message: '请输入商户名称', trigger: 'blur' },
          { max: 20, message: '最多输入20个字符', trigger: 'blur' }
        ],
        linkmanName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          { max: 20, message: '最多输入20个字符', trigger: 'blur' }
        ],
        linkmanPhone: [
          { required: true, message: '请输入联系人手机号', trigger: 'blur' },
          { max: 11, message: '最多输入11个字符', trigger: 'blur' },
          {
            pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
            message: '请输入有效的手机号码',
            trigger: ['blur']
          }
        ],
        linkmanMail: [
          { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
          {
            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入正确的邮箱格式',
            trigger: ['blur']
          },
          { max: 50, message: '最多输入50位字符', trigger: 'change' }
        ]
      }

    };
  },
  created () {
    const saveData = this.getFormData()
    if (saveData) {
      this.data1 = saveData
    }
    this.data1.linkmanPhone = this.data1.coLinkmanPhone = sessionStorage.getItem('user_phone')
  },
  methods: {
    // 商户基本信息提交
    handleBack () {
      this.$refs['merchantInfoFrom'].resetFields()
      this.$router.push('mallGuide')

    },
    handleSave () {
      this.$refs['merchantInfoFrom'].validate(valid => {
        if (!valid) return
        this.saveFormData()
      })
    },
    handleNext () {
      this.$refs['merchantInfoFrom'].validate(valid => {
        if (!valid) return
        this.createMerchant(this.data1)
      })
    },
    saveFormData () {
      localStorage.setItem('merchant_info', JSON.stringify(this.data1))
    },
    getFormData () {
      return JSON.parse(localStorage.getItem('merchant_info')) || {}
    },
    async createMerchant (data) {
      this.loading = true
      const res = await createMerchant(data)
      if (res && [0, 1].indexOf(res.data.code) !== -1) {
        this.merId = res.data.merId || ''
        this.merId = ''
        console.log('id ==== ', res.data)
        this.$set(this.data1, 'coLinkmanPhone', this.data1.linkmanPhone)
        this.$set(this.data1, 'coLinkmanMail', this.data1.linkmanMail)
        sessionStorage.setItem('merchant_code', res.data.data.code)
        this.data1 = {
          ...this.data1,
          ...res.data.data
        }
        this.saveFormData()
        this.loading = false
        this.$router.push('companyInfo')
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title1 {
  width: 80%;
  margin: 20px auto;
  font-size: 20px;
}
.nav {
  width: 80%;
  margin: 20px auto;
}
.main_card {
  width: 80%;
  margin: 20px auto;
  box-sizing: border-box;

  .el-form-item {
    margin-bottom: 20px;
  }
  .title {
    font-size: 18px;
    margin: 0;
  }
  .divider {
    margin: 8px 0 24px 0;
  }
  //  商户基本信息
  .merchantInfoFrom {
    width: 40%;
  }

  .header_steps {
    width: 80%;
    margin: 40px auto;
  }
}

.btn-group {
  display: flex;
  justify-content: center;
  margin: 20px;

  .btn {
    width: 124px;
    height: 40px;
    border-radius: 5px;
    margin: 0 20px;
    font-size: 18px;
  }
}
.bg_logo_name {
  color: #000000 !important;
}
</style>
